<template>
    <v-app id="error_app">
        <v-container d-flex id="error_cont">
            <v-row>
                <v-col cols="12" class="align-center ">
                    <v-card class="elevation-3 pa-3">
                        <v-card-title class="text-center justify-center">
                            <div>
                                <v-img src="@/assets/image/LogoGris.png" contain width="350px" height="auto"></v-img>
                                <h3 class="my-4 primary--text">{{$store.state.appName}}</h3>
                            </div>
                        </v-card-title>
                        <v-card-text class="text-center">
                            <v-icon size="50" color="error" error>sentiment_very_dissatisfied</v-icon>
                            <h3>La página no existe.</h3>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn
                                class="text-capitalize"
                                dark
                                large
                                outlined
                                color="primary"
                                to="/"
                            >
                               Ir al Inicio
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import config from '@/config';

export default {
    name: 'Error',
    data() {
        return {
            config
        }
    }
};
</script>

<style src="./Error.scss" lang="scss"></style>
